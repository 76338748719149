import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';

import './index.scss';
import '../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const ExiliumTierPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page exilium-page'} game="exilium">
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/exilium/categories/category_tier.jpg"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Girls' Frontline 2: Exilium Tier List</h1>
          <h2>
            Prydwen's tier list for Girls' Frontline 2: Exilium that rates all
            currently available characters.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Work in progress" />
      <div className="banner countdown">
        <div className="cta">
          <h4>
            Our tier list for Girls' Frontline 2: Exilium is currently being
            created!
          </h4>
          <p>The tier list will be released soon!</p>
        </div>
      </div>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default ExiliumTierPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier List | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="Prydwen's tier list for Girls' Frontline 2: Exilium that rates all currently available characters."
    game="exilium"
  />
);
